import { defaultTextboxData, TextboxTemplate } from '../../components/textbox/c-textbox.template'

export const AutocompleteTemplate = (d) => {
  return `<div id="${d.id}" class="c-autocomplete${appendDataType(d.dataType)} ${d.extraClasses}"
  ${d.floatsFrom ? 'data-c-autocomplete__floats-from=' + d.floatsFrom : 'xs'}
  ${d.forceOpenUntil ? 'data-c-autocomplete__force-open-until=' + d.forceOpenUntil : ''}
  ${d.jsApi ? 'data-js-component=c-autocomplete' : ''}
  ${d.track ? 'data-track=' + d.track : ''}>
  ${TextboxTemplate({
    ...defaultTextboxData,
    icon: d.iconToken,
    jsApi: d.jsApi,
    iconPosition: d.iconPosition,
    id: d.id + '__textbox',
    name: d.name,
    label: d.label,
    value: d.value,
    autocomplete: false,
    hasClearButton: d.hasClearButton,
    placeholder: d.placeholder,
    attributes: { 'data-c-autocomplete__textbox': '' },
    elementAttributes: d.maxLength > 0 ? { maxlength: d.maxLength } : ''
})}
${printLocales(d.id, d.locales)}  
</div>`
}

function appendDataType (dataType) {
  return `${dataType ? ' c-autocomplete--' + dataType : ''}`
}

function printLocales (componentId, locales) {
  return locales && Object.values(locales).length > 0
    ? `<script type="application/json" data-type="i18n" data-uid="${componentId}">${JSON.stringify(locales)}</script>`
    : ''
}
