import Calendar from '../../components/calendar/main'
import CalendarDataModel from '../../components/calendar/calendar-data-model'
import domEventsHelper from '../../../js/document/dom-events-helper'
import {
  dateToString,
  getDatesDifference,
  getNDaysBeforeAndAfter,
  getWholeMonthDays,
  getEdgeDates,
  getMonthEdges,
  formatDate
} from '../../../js/helpers/dates'
import registeredEvents from '../../../js/helpers/registered-events'
import { datesFilterEvents } from '../../../js/document/event-types'
import { queueFilterEvent } from '../../../js/helpers/queue-filter-event'

const widgetApi = 'w-dates-filter'
const widgetQueries = {
  textBox: `[data-${widgetApi}__textbox]`,
  floatingBox: `[data-${widgetApi}__floating-box]`,
  calendar: `[data-${widgetApi}__calendar]`,
  offset: `[data-${widgetApi}__offset]`,
  cancel: `[data-${widgetApi}__action="cancel"]`,
  submit: `[data-${widgetApi}__action="submit"]`,
  track: 'data-track',
  selectMonth: '.select-month',
  selectMonthCheckbox: '.select-month > input[type=checkbox]'
}

const EventEmitter = require('eventemitter3')

export default class DatesFilter {
  /**
   * Creates a new DatesFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DestinationsFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element
    this.events = new EventEmitter()
    // Run the queries finding for elements
    this.textBox = this.element.querySelector(widgetQueries.textBox)
    this.textBoxInput = this.textBox.querySelector('input')
    this.floatingBox = this.element.querySelector(widgetQueries.floatingBox)
    this.calendar = this.element.querySelector(widgetQueries.calendar)
    this.offset = this.element.querySelector(widgetQueries.offset)
    this.cancel = this.element.querySelector(widgetQueries.cancel)
    this.submit = this.element.querySelector(widgetQueries.submit)
    this.selectMonthEl = this.element.querySelector(widgetQueries.selectMonth)
    this.selectMonthCheckbox = this.element.querySelector(widgetQueries.selectMonthCheckbox)
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.track) && { track: this.element.attributes[widgetQueries.track].value }
    })
    this.element.setAttribute('data-js-component', widgetApi)
    // Access to available elements API
    this.textBoxApi = this.textBox['c-textbox']
    this.floatingBoxApi = this.floatingBox['c-floating-box']
    this.offsetApi = this.offset['c-dropdown']

    // Init component state
    this.isOpened = false

    // Bind component events
    this.textBoxApi.events.on('focus', this.onFocus, this)
    this.textBoxInput.addEventListener('focus', (ev) => {
      this.onFocus()
      ev.stopPropagation()
    })

    this.textBoxApi.events.on('clear', () => {
      this.selection.setAttribute('selectedDates', [])
    })
    this.offsetApi.events.on('propChanged', (prop) => {
      if (prop.name === 'value') this.onOffsetChange(prop.value)
    })

    // Bind DOM events
    this._domEvents = [
      [this.cancel, { click: (ev) => this.onCancel(ev) }],
      [this.submit, { click: (ev) => this.onSubmit(ev) }],
      [this.floatingBox, { mousedown: (ev) => this.stopPropagation(ev) }]
    ]
    domEventsHelper.attachEvents(this._domEvents, widgetApi)

    this.element[widgetApi] = {
      open: (this.onFocus).bind(this)
    }
    // Init filterModel
    this.setFilterModel(filterModel)

    // Initialize calendar element
    this.calendarApi = new Calendar(this.calendar, this.tempSelection, undefined, this)

    if (this.selectMonthEl) {
      this.nextMonthEl = element.querySelector('.flatpickr-next-month')
      this.prevMonthEl = element.querySelector('.flatpickr-prev-month')

      this.selectMonthEl.addEventListener('change', () => { this.selectMonth() })
      this.nextMonthEl.addEventListener('mousedown', () => { this.checkSelectMonthCheckBox(true) })
      this.prevMonthEl.addEventListener('mousedown', () => { this.checkSelectMonthCheckBox(false) })
    }

    this.calendarApi.setSelectionPreprocessingCallback(this.getSelectedDatesCallback)

    this.closeFloatingBox()
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set props on child views
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel - The associated FilterModel
   *
   * @returns {DatesFilter} self instance
   */
  setFilterModel (filterModel) {
    this.filterModel = filterModel

    this.selection = this.filterModel.values.calendarDataModel
    this.tempSelection = new CalendarDataModel(this.filterModel.values.calendarDataModel.attributes)

    this.textBoxApi.setProp('value', this.selection.humanizedSelectedDates())

    if (this.calendarApi) this.calendarApi.updateCalendarData(this.tempSelection)

    this.selection.events.on('change', this.onCalendarDataModelChange, this)

    this.tempSelection.events.on('change', () => {
      this.restoreOffsetFromSelection(this.tempSelection)
    })

    return this
  }

  openFloatingBox () {
    this.textBoxInput.classList.add('is-focused')
    this.floatingBoxApi.setProp('opened', true)
    this._floatingBoxEvents = [
      [document, { mousedown: (ev) => this.onDocumentClick(ev) }],
      [this.textBox, { click: (ev) => this.stopPropagation(ev) }]
    ]
    domEventsHelper.attachEvents(this._floatingBoxEvents, widgetApi)
    this.mustSelectMonthOptionCheckbox()
  }

  closeFloatingBox () {
    domEventsHelper.detachEvents(this._floatingBoxEvents, widgetApi)
    const closingPromise = this.floatingBoxApi.setProp('opened', false)
    this.textBoxInput.classList.remove('is-focused')
    this.textBoxInput.blur()
    return closingPromise
  }

  onFocus (ev) {
    if (this.isOpened) return
    this.isOpened = true

    const { cid, ...restoreAttributes } = this.selection.attributes
    this.tempSelection.setAttributes(restoreAttributes)
    this.restoreOffsetFromSelection()
    this.openFloatingBox()
  }

  onCancel (ev) {
    this.isOpened = false
    const { cid, ...restoreAttributes } = this.selection.attributes

    this.closeFloatingBox()
      .then(() => {
        this.tempSelection.setAttributes(restoreAttributes)
        this.restoreOffsetFromSelection()
      })
  }

  onSubmit (ev) {
    this.isOpened = false
    this.closeFloatingBox()
    const areSameValues = this.tempSelection.attributes.selectedDates.length === this.selection.attributes.selectedDates.length &&
      this.tempSelection.attributes.selectedDates.sort().every((v, i) => v === this.selection.attributes.selectedDates.sort()[i])
    const { cid, ...newAttributes } = this.tempSelection.attributes
    this.selection.setAttributes(newAttributes)
    if (!areSameValues) this.events.emit(datesFilterEvents.DATES_FILTERS_APPLIED, this.selection.attributes.selectedDates)
  }

  onCalendarDataModelChange (ev) {
    this.textBoxApi.setProp('value', this.selection.humanizedSelectedDates())
    this.restoreOffsetFromSelection()
  }

  onOffsetChange (offset) {
    const selectedDate = this.tempSelection.getAttribute('selectedDate')
    if (!selectedDate) {
      this.offsetApi.setProp('value', offset, { silent: true })
      return
    }
    const selectedDates = (() => {
      switch (offset) {
        case '0':
          return [selectedDate]
        default:
          return getNDaysBeforeAndAfter(selectedDate, parseInt(offset)).map(d => dateToString(d))
      }
    })()
    this.tempSelection.setAttribute('selectedDates', selectedDates)
  }

  onDocumentClick (ev) {
    const closestResult = (el, lastParentToCheck) => {
      do {
        if (el === lastParentToCheck) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el !== lastParentToCheck.parentElement && el.nodeType === 1)
      return null
    }
    const delegateTarget = closestResult(ev.target, this.element)
    if (!delegateTarget) {
      const shouldEmit = !(this.tempSelection.attributes.selectedDates.length === this.selection.attributes.selectedDates.length &&
      this.tempSelection.attributes.selectedDates.sort().every((v, i) => v === this.selection.attributes.selectedDates.sort()[i]))
      queueFilterEvent(ev, this.element, this.onSubmit.bind(this), shouldEmit)
    }
  }

  stopPropagation (ev) {
    ev.stopPropagation()
  }

  restoreOffsetFromSelection (selection = this.selection) {
    if (this.selectingWholeMonth) return

    const selectedDates = selection.getAttribute('selectedDates')

    // Single or empty date
    if (selectedDates.length <= 1) {
      this.offsetApi.setProp('value', '0', { silent: true })
      if (!this.selectingWholeMonth) {
        this.unselectMonth()
      }
      return this
    }

    // Try with days difference
    const selectedDate = selection.getAttribute('selectedDate')
    const daysDifference = getDatesDifference(selectedDates[0], selectedDate)
    this.offsetApi.setProp('value', daysDifference.toString(), { silent: true })
      .then(() => {
        return this
      })
      .catch((e) => {
        this.offsetApi.setProp('value', '0', { silent: true })
          .then(() => {
            return this
          })
      })

    if (!this.selectingWholeMonth) {
      this.unselectMonth()
    }
    return this
  }

  checkSelectMonthCheckBox (checkNextMonth) {
    const { currentMonth } = this.calendarApi.flatpickr

    // Check for December and January
    const month = checkNextMonth
      ? currentMonth === 11 ? 0 : currentMonth + 1
      : currentMonth === 0 ? 11 : currentMonth - 1

    if (this.selectedMonth === month) {
      this.selectMonthCheckbox.checked = true
    } else {
      this.unselectMonth(undefined, false)
    }
  }

  mustSelectMonthOptionCheckbox () {
    if (!this.selection || !this.selectMonthEl) return

    const { currentMonth, currentYear } = this.calendarApi.flatpickr

    const selectedDates = this.selection.getAttribute('selectedDates')

    const firstAviableDayForMonth = this.filterModel.values.getFirstAvailableDateForMonth(currentMonth, currentYear)
    if (!firstAviableDayForMonth) return

    const aviableEdges = [
      firstAviableDayForMonth,
      dateToString(getMonthEdges(firstAviableDayForMonth)[1])
    ]

    const selectedEdges = getEdgeDates(selectedDates).map(d => dateToString(d))

    // Whole month
    if (JSON.stringify(aviableEdges) === JSON.stringify(selectedEdges)) {
      this.selectMonthCheckbox.checked = true
      this.selectedMonth = currentMonth
    }
  }

  selectMonth () {
    if (!this.selectMonthEl) return
    if (this.selectMonthCheckbox.checked) {
      const { currentMonth, currentYear } = this.calendarApi.flatpickr
      if (this.selectedMonth !== currentMonth) {
        this.selectingWholeMonth = true
        this.isMonthSelectChecked = true
        // select first day of month
        const newDate = this.filterModel.values.getFirstAvailableDateForMonth(currentMonth, currentYear)
        this.tempSelection.setAttribute('selectedDate', newDate)
        // select whole month
        const selectedDates = getWholeMonthDays(newDate).map(d => dateToString(d))
        this.tempSelection.setAttribute('selectedDates', selectedDates)
        this.selectedMonth = currentMonth
        this.selectingWholeMonth = false
      }
    } else {
      this.unselectMonth(true)
    }
  }

  unselectMonth (resetSelection = false, forgetSelectedMonth = true) {
    if (!this.selectMonthEl) return
    if (resetSelection) {
      const selectedDate = this.tempSelection.getAttribute('selectedDate')
      const offset = this.offsetApi.getProp('value')
      switch (offset) {
        case 0:
          this.tempSelection.setAttribute('selectedDates', [selectedDate])
          break
        default: {
          const selectedDates = getNDaysBeforeAndAfter(selectedDate, parseInt(offset))
          this.tempSelection.setAttribute('selectedDates', selectedDates)
          break
        }
      }
      this.selectedMonth = null
    } else if (forgetSelectedMonth) {
      this.selectMonthCheckbox.checked = false
      this.selectedMonth = null
    } else {
      this.selectMonthCheckbox.checked = false
    }
  }

  getSelectedDatesCallback (selectedDate, parentInstance) {
    const offset = parentInstance.offsetApi.getProp('value')
    let selectedDates = []
    let mode
    switch (offset) {
      case '0':
        mode = 'single'
        selectedDates = selectedDate
        break
      default:
        mode = 'multiple'
        selectedDates = getNDaysBeforeAndAfter(formatDate(selectedDate), parseInt(offset))
        break
    }

    const result =
    {
      mode,
      selectedDate,
      selectedDates
    }
    return result
  }
}
