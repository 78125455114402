import { destinationIcons } from '../../../js/data/filters/destinations/icons-config'

export const DestinationResultTemplate = (d, options) => {
  return `<li id="${d.getAttribute('value')}" class="c-autocomplete__result" data-destination-type="${d.getAttribute('type')}" aria-selected="false" data-cid="${d.cid}">
<span data-attribute="caption">
        ${options.regExp
            ? d.getAttribute('caption').replace(options.regExp, '<mark>$1</mark>')
            : d.getAttribute('caption')
        }
        ${options.hideCounters
            ? ''
            : `<span data-attribute="count">${d.getAttribute('count').toString()}</span>`}        
</span>
<div class="c-autocomplete__result-parents">
  ${d.getParentModels().map(parent => `
    <span data-attribute="parent" data-parent-type="${parent.getAttribute('type')}">
      ${parent.getAttribute('caption')}
    </span>
  `).join('')}
</div>
<i class="m-icon m-icon--${destinationIcons(d.getAttribute('siteType'), d.getAttribute('type'))}"></i>
</li>`
}
