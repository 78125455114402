export const DestinationMultipleOptionTemplate = (d, options) => {
  return `<div class="c-checkbox
    ${d.hasChildren ? 'has-child w-destination-multiple-collapse' : ''} c-choice-list__option c-checkbox__bottom-line"
    ${d.hasChildren ? `data-collapse="${d.value}"` : ''}>
     <input class="c-checkbox__input" type="checkbox" id="${d.caption}-${d.value}-${d.parentId}" name="${d.caption}" value="${d.value}" data-text="${d.caption}" data-image-src="" data-image-alt=""
    ${d.isSelected ? 'checked' : ''}/>
      <label class="c-checkbox__label" for="${d.caption}-${d.value}-${d.parentId}">
       <div class="c-checkbox__element"></div>
       <div class="c-checkbox__text-wrapper">
        <div class="c-checkbox__text-content">
         <span class="c-checkbox__text">${d.caption} <span class="w-destination-multiple__checkbox-value">(${d.count})</span>
         </span>
        </div>
        ${d.hasChildren ? `<div class="m-icon m-icon--chevron-down" data-collapse-chevron="${d.value}"></div>` : ''}
      </div>
      </label>
    </div>`
}
