import { AutocompleteTemplate } from '../../components/autocomplete/autocomplete.template'

export const DestinationSingleFilterTemplate = (d) => {
  return `${AutocompleteTemplate({
    id: d.id + '__autocomplete',
    jsApi: true,
    label: d.label,
    dataType: 'destinations',
    iconToken: 'pin',
    iconPosition: 'left',
    extraClasses: d.extraClasses,
    placeholder: d.placeholder,
    value: d.value,
    hasClearButton: true,
    track: d.track,
    locales: d.locales
})}`
}
