import { DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import Component from '../../../js/core/component/component'
import Swipe from '../../objects/swipe/main'
import { elementFromString } from '../../../js/document/html-helper'
// Ensure other child component APIs are loaded on time
require('../../components/dropdown/main')
require('../../components/dropdown/dropdown-multiple/main')
require('../../components/choice-list/main')
require('../../components/floating-box/main')

const widgetApi = 'w-dropdown-filter'
const widgetApiMultiple = 'w-dropdown-multiple-filter'
const widgetQueries = {
  dropdown: `[data-${widgetApi}__dropdown]`,
  dropdownMultiple: `[data-${widgetApiMultiple}__dropdown]`,
  placeholder: 'data-c-dropdown__placeholder',
  okText: 'data-c-dropdown__ok-text',
  cancelText: 'data-c-dropdown__cancel-text',
  icon: 'data-c-dropdown__icon',
  track: 'data-track'
}

export default class DropdownDynamicFilter {
  /**
   * Creates a new DropdownFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DropdownFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element

    this.setFilterModel(filterModel, true)
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set options on dropdown
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel - The associated FilterModel
   *
   * @returns {DropdownFilter} self instance
   */
  setFilterModel (filterModel, isFirstLoad = false) {
    const oldValues = this.getFilterModelValuesAsText(this.filterModel)
    const newValues = this.getFilterModelValuesAsText(filterModel)
    this.filterModel = filterModel
    this.element.setAttribute('data-js-component', widgetApi)
    this.icon = this.element.getAttribute(widgetQueries.icon) || 'car'
    this.placeholder = this.element.getAttribute(widgetQueries.placeholder) || ''
    this.okText = this.element.getAttribute(widgetQueries.okText) || ''
    this.cancelText = this.element.getAttribute(widgetQueries.cancelText) || ''

    if (isFirstLoad || oldValues !== newValues) {
      this.rebuildHTML()
    }

    // Setting options silently so that value prop changed is not triggered in case it changes when changing options
    const setDropdownOptions = () => {
      this.dropdownApi.setProp('options', this.getDropdownOptionsFromFilter(), { silent: true })
      this.dropdownApi.setProp('saveOnDomClick', true)
    }
    setDropdownOptions()

    this.filterModel.events.on('change', () => {
      setDropdownOptions()
    })

    return this
  }

  rebuildHTML () {
    const newContent = this._getDropdownHtml(this.filterModel)
    this.element.innerHTML = ''
    this.element.appendChild(newContent)
    Component.initDocumentComponentsFromAPI(newContent)
    Swipe.CreateInstancesOnDocument()
    const isMultiselectable = this.filterModel.attributes.isMultiselectable
    const name = `c-dropdown${isMultiselectable ? '-multiple' : ''}`
    this.dropdownElement = this.element.querySelector(isMultiselectable ? widgetQueries.dropdownMultiple : widgetQueries.dropdown)
    this.dropdownApi = this.dropdownElement[name]
    this.element[widgetApi] = {
      open: this.dropdownApi.open
    }
    this.dropdownApi.events.on(isMultiselectable ? 'submit' : 'change',
      (value) => {
        this.setDropdownOptionsToFilter(value, isMultiselectable)
      })
  }

  /**
   * Set the current Dropdown selectedValues to filter
   *
   * @returns {DropdownFilter} self instance
   */
  setDropdownOptionsToFilter (value, isMultiselectable) {
    let newSelectedValues
    if (isMultiselectable) {
      newSelectedValues = this.dropdownApi.getSelectedValues()
    } else {
      newSelectedValues = [value]
    }
    this.filterModel
      .clearSelection({ silent: true })
      .setSelectedValues(newSelectedValues)
    return this
  }

  _getDropdownHtml (filterModel) {
    const sharedFields = {
      hiddenLabel: !this.filterModel.attributes.label,
      label: this.filterModel.attributes.label || '',
      placeholder: this.placeholder,
      isMultiple: this.filterModel.attributes.isMultiselectable,
      options: this.getDropdownOptionsFromFilter(),
      required: this.filterModel.attributes.isMandatory,
      icon: this.icon,
      track: this.element.getAttribute(widgetQueries.track)
    }

    if (!filterModel.attributes.isMultiselectable) {
      return elementFromString(DropdownTemplate({
        ...sharedFields,
        hasClearButton: !this.filterModel.attributes.isMandatory,
        attributes: { 'data-w-dropdown-filter__dropdown': '' }
      }))
    } else {
      const ddHtml = DropdownTemplate({
        ...sharedFields,
        cancelButtonText: this.cancelText,
        okButtonText: this.okText,
        attributes: { 'data-w-dropdown-multiple-filter__dropdown': '' }
      })
      return elementFromString(ddHtml)
    }
  }

  getDropdownOptionsFromFilter () {
    return this.filterModel.values.models.map(model => ({
      value: model.getAttribute('value'),
      text: model.getAttribute('caption'),
      count: model.getAttribute('count'),
      selected: model.getAttribute('isSelected'),
      checked: model.getAttribute('isSelected'),
      disabled: !model.getAttribute('isAvailable')
    }))
  }

  getFilterModelValuesAsText (filterModel) {
    return filterModel ? filterModel.values.models.map(model => model.getAttribute('value')).join('|') : ''
  }
}
