import * as namespace from '../document/namespace'
const helper = namespace.register('sundio.helpers.dates')

/**
 * A date expressed as "YYYY-MM-DD" string
 * @global
 * @typedef {String} DateString
 */

/**
 * Check if date is invalid
 *
 * @param {Date} date
 *
 * @returns {Boolean}
 */
export function isInvalidDate (date) {
  return (date instanceof Date && isNaN(date))
}
helper.isInvalidDate = isInvalidDate

/**
 * Check if date is a valid date object
 *
 * @param {Date} date
 *
 * @returns {Boolean}
 */
export function isValidDateObject (date) {
  // See: https://stackoverflow.com/a/44198641/748941 on how to detect if an object is a (valid) date instance.
  return (date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date))
}
helper.isValidDateObject = isValidDateObject

/**
 * Check if date is invalid
 *
 * @param {DateString} datestring
 *
 * @returns {Boolean}
 */
export function isValidDateString (datestring) {
  if (typeof datestring === 'string') {
    const date = dateStringToDate(datestring)
    const month = Number(datestring.split('-')[1]) - 1
    return (date.getMonth() === month)
  } else if (datestring instanceof Date) {
    return !isInvalidDate(datestring)
  } else {
    return false
  }
}
helper.isValidDateString = isValidDateString

/**
 * Convert a DateString|Date to Date if needed
 *
 * @param {Date|DateString} date
 *
 * @returns {Date}
 */
export function dateStringToDate (date) {
  return isValidDateObject(date)
    ? date
    : (typeof date === 'string') ? new Date(fixDateString(date)) : new Date()
}
helper.dateStringToDate = dateStringToDate

/**
 * Fixes date for browsers like IE or Firefox
 *
 * @param {DateString} datestring
 *
 * @returns {DateString}
 */
export function fixDateString (datestring) {
  let [year, month, day] = datestring.split('-')
  if (!year || !month || !day) { return '' }

  year = (year.length === 2) ? '19' + year : year
  month = (month.length === 1) ? '0' + month : month
  day = (day.length === 1) ? '0' + day : day

  // We need to replace unicode characters to avoid the Invalid Date problem thrown by
  // IE 11 when performing a new Date()
  return [year, month, day].join('-').replace(/[^ -\x7F]/g, '')
}
helper.fixDateString = fixDateString

/**
 * Convert a DateString|Date array to Dates array if needed
 *
 * @param {Date[]|DateString[]} dates
 *
 * @returns {Date[]}
 */
export function dateStringsToDates (dates) {
  return dates.map(date => dateStringToDate(date))
}
helper.dateStringsToDates = dateStringsToDates

/**
 * Returns the minimum (earliest) date of an array.
 * If the array is empty, no minimum is returned (it is undefined).
 *
 * @param {Date[]|DateString[]} dates=[] - Set of dates from which the minimum will be obtained. Empty array by default.
 *
 * @returns {Date|undefined} The minimum, or earliest, date of the array.
 */
export function getMin (dates = []) {
  if (!dates.length) return undefined
  dates = dateStringsToDates(dates)
  return new Date(Math.min(...dates))
}
helper.getMin = getMin

/**
 * Returns the maximum (latest) date of an array.
 * If the array is empty, no maximum is returned (it is undefined).
 *
 * @param {Date[]|DateString[]} dates=[] - Set of dates from which the maximum will be obtained. Empty array by default.
 *
 * @returns {Date|undefined} The maximum, or latest, date of the array.
 */
export function getMax (dates = []) {
  if (!dates.length) return undefined
  dates = dateStringsToDates(dates)
  return new Date(Math.max(...dates))
}
helper.getMax = getMax

/**
 * Returns the edges (first and last) dates of an array.
 * If the array is empty, empty array is returned.
 *
 * @param {Date[]|DateString[]} dates=[] - Set of dates from which the edges will be obtained. Empty array by default.
 *
 * @returns {Date[]} The edges dates of the array.
 */
export function getEdgeDates (dates = []) {
  if (!dates.length) return []
  dates = dateStringsToDates(dates)
  return [getMin(dates), getMax(dates)]
}
helper.getEdgeDates = getEdgeDates

/**
 * Returns the string representation of the date provided in the format and locale indicated.
 * The date parameter is mandatory and it needs to be provided.
 * Not all formats are supported. Currently, the formats supported are:
 * - yyyy-mm-dd (for example 2008-10-28)
 * - dd mmmm yyyy (for example 28 October 2008 - with the month in the language of the locale)
 * - d mmmm yyyy (for example 8 October 2008 - with the date not being necessarily two digits and the month in the language of the locale)
 * - mmmm yyyy (for example 2008 - with the month in the language of the locale)
 * If no format parameter is provided, the ISO date: yyyy-mm-dd is used.
 *
 * @param {Date} date - The date which string representation is desired
 * @param {string} format - The format in which the string representation is desired.
 * If not provided, the default format used is the ISO Date: yyyy-mm-dd (e.g. 2008-10-28)
 * @param {string} locale - A string indicating the locale in which to obtain the dates representation.
 * If not provided, the locale en-GB will be used.
 *
 * @returns {String|undefined} The formatted string
 */
export function formatDate (date, format = 'yyyy-mm-dd', locale = 'en-GB') {
  if (date) {
    switch (format) {
      case 'dd mmmm yyyy':
        return `${date.toLocaleString(locale, { day: '2-digit' })} ${date.toLocaleString(locale, { month: 'long' })} ${
          date.toLocaleString(locale, { year: 'numeric' })}`
      case 'd mmmm yyyy':
        return `${date.toLocaleString(locale, { day: 'numeric' })} ${date.toLocaleString(locale, { month: 'long' })} ${
          date.toLocaleString(locale, { year: 'numeric' })}`

      case 'mmmm yyyy':
        return date.toLocaleString(locale, { month: 'long', year: 'numeric' })

      default:
        return `${date.toLocaleString(locale, { year: 'numeric' })}-${date.toLocaleString(locale, { month: '2-digit' })
        }-${date.toLocaleString(locale, { day: '2-digit' })}`
    }
  }
  return undefined
}
helper.formatDate = formatDate

/**
 * Creates a Data object from a string in the provided format.
 * Not all formats are supported. Currently, the formats supported are:
 * - yyyy-mm-dd (for example 2008-10-28)
 *
 * @param {string} dateText - The string representation of the date object to create
 * @param {string} format - The format in which the string represents the date to create.
 * If not provided, the default format is  'yyyy-mm-dd'
 *
 * @returns {Date|undefined} The date object that corresponds to the provided dateText representation.
 * If format is not supported or dateText does not follows provided format, it is undefined.
 */
export function createDate (dateText, format = 'yyyy-mm-dd') {
  if (dateText) {
    const fixDateText = fixDateString(dateText)
    dateText = fixDateText.split('T')[0]
    switch (format) {
      case 'yyyy-mm-dd': {
        const parts = dateText.split('-')
        if (parts.length === 3) {
          const yearPart = parts[0]
          const monthPart = parts[1]
          const datePart = parts[2]
          if (yearPart.length === 4 && monthPart.length === 2 && datePart.length === 2) {
            return new Date(parseInt(yearPart), parseInt(monthPart) - 1, parseInt(datePart))
          }
        }
      }
    }
  }
  return undefined
}
helper.createDate = createDate

/**
 * Returns the difference between two dates.
 *
 * @param {Date|DateString} initialDate
 * @param {Date|DateString} finalDate
 * @param {string} differenceUnits - The unit in which the difference will be returned. By default is days
 * Not all difference units are supported. Currently supported are:
 * - milliseconds
 * - seconds
 * - minutes
 * - hours
 * - days
 * - weeks
 *
 * @returns {number|undefined} The difference between firstDate and secondDate in the units specified.
 */
export function getDatesDifference (initialDate, finalDate, differenceUnits = 'days') {
  if (initialDate && finalDate) {
    initialDate = dateStringToDate(initialDate)
    finalDate = dateStringToDate(finalDate)
    const millisecondsDifference = finalDate.getTime() - initialDate.getTime()
    switch (differenceUnits) {
      case 'milliseconds':
        return millisecondsDifference
      case 'seconds':
        return millisecondsDifference / 1000
      case 'minutes':
        return millisecondsDifference / (1000 * 60)
      case 'hours':
        return millisecondsDifference / (1000 * 60 * 60)
      case 'days':
        return millisecondsDifference / (1000 * 60 * 60 * 24)
      case 'weeks':
        return millisecondsDifference / (1000 * 60 * 60 * 24 * 7)
    }
  }
  return undefined
}
helper.getDatesDifference = getDatesDifference

/**
 * Returns first and last date in current month for a given date.
 *
 * @param {Date|DateString} date
 *
 * @returns {Date[]} A dates array with first and last dates in current month.
 */
export function getMonthEdges (date) {
  date = dateStringToDate(date)
  return [
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  ]
}
helper.getMonthEdges = getMonthEdges

/**
 * Returns all dates in current month for a given date.
 *
 * @param {Date|DateString} date
 *
 * @returns {Date[]} A dates array with all available dates in current month.
 */
export function getWholeMonthDays (date) {
  const monthEdges = getMonthEdges(date)
  return getConsecutivePeriod(monthEdges[0], monthEdges[1])
}
helper.getWholeMonthDays = getWholeMonthDays

/**
 * Returns a consecutive dates array in between given two dates.
 *
 * @param {Date|DateString} initialDate
 * @param {Date|DateString} finalDate
 *
 * @returns {Date[]} A dates array filled with missing days gaps.
 */
export function getConsecutivePeriod (initialDate, finalDate) {
  initialDate = dateStringToDate(initialDate)
  finalDate = dateStringToDate(finalDate)
  initialDate.setHours(0, 0, 0)
  finalDate.setHours(0, 0, 0)
  const days = []
  const newDay = new Date(initialDate)
  while (newDay.getTime() <= finalDate.getTime()) {
    days.push(new Date(newDay))
    newDay.setDate(newDay.getDate() + 1)
    newDay.setHours(0, 0, 0) // fix changes on daylight saving time over dates
  }
  return days
}
helper.getConsecutivePeriod = getConsecutivePeriod

/**
 * Returns a date, adding or subtracting N days to a given date
 *
 * @param {Date|DateString} date
 * @param {Number} daysToAdd - The number of days to add/subtract
 *
 * @returns {Date} Resulting date
 */
export function addDays (date, daysToAdd) {
  date = dateStringToDate(date)
  const newDate = new Date(date.getTime())
  newDate.setDate(date.getDate() + daysToAdd)
  return newDate
}
helper.addDays = addDays

/**
 * Returns a date, adding or subtracting N days to a given date
 *
 * @param {Date|DateString} date
 * @param {Object} addToDate - Whatever needs to be added (years, months, days, hours, minutes or seconds)
 *
 * @returns {Date} Resulting date
 */
export function add (date, addToDate = {}) {
  date = dateStringToDate(date)
  const newDate = new Date(date.getTime())
  if (addToDate.years) { newDate.setFullYear(date.getFullYear() + Number(addToDate.years)) }
  if (addToDate.months) { newDate.setMonth(date.getMonth() + Number(addToDate.months)) }
  if (addToDate.days) { newDate.setDate(date.getDate() + Number(addToDate.days)) }
  if (addToDate.hours) { newDate.setHours(date.getHours() + Number(addToDate.hours)) }
  if (addToDate.minutes) { newDate.setMinutes(date.getMinutes() + Number(addToDate.minutes)) }
  if (addToDate.seconds) { newDate.setSeconds(date.getSeconds() + Number(addToDate.seconds)) }

  return newDate
}
helper.add = add

/**
 * Returns an array of dates, resulting from adding and subtracting N days to a given date
 *
 * @param {Date|DateString} date
 * @param {Number} daysDiff - The number of days to add and subtract
 *
 * @returns {Date[]} A sorted dates array with computed calculation
 */
export function getNDaysBeforeAndAfter (date, daysDiff) {
  date = dateStringToDate(date)
  const days = []

  if (daysDiff < 1) {
    days.push(date)
    return days
  }

  for (let i = daysDiff; i > 0; i--) {
    const newDay = new Date(date.getTime())
    newDay.setDate(date.getDate() - i)
    days.push(newDay)
  }

  days.push(date)

  for (let i = 0; i < daysDiff; i++) {
    const newDay = new Date(date.getTime())
    newDay.setDate(date.getDate() + i + 1)
    days.push(newDay)
  }

  return days
}
helper.getNDaysBeforeAndAfter = getNDaysBeforeAndAfter

/**
 * Compare 2 dates for equality ignoring the time.
 *
 * @param {Date} date1 - First date to compare.
 * @param {Date} date2 - Second date to compare.
 *
 * @returns {Boolean} True if the date (disregarding the date) are equal.
 */
export function equalDates (date1, date2) {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  d1.setHours(0, 0, 0, 0)
  d2.setHours(0, 0, 0, 0)

  return d1.getTime() === d2.getTime()
}
helper.equalDates = equalDates

/**
 * Convert the date object into a string in ISO format (YYYY-MM-DD).
 *
 * @param {Date} date - The date to be stringified.
 *
 * @returns {DateString} The date in ISO format (YYYY-MM-DD).
 */
export function dateToString (date) {
  if (isInvalidDate(date)) { return '' } else {
    const mm = date.getMonth() + 1
    const dd = date.getDate()

    return [date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('-')
  }
}
helper.dateToString = dateToString
/**
 * Check if both given dates are on same month
 *
 * @param {Date|DateString} date1
 * @param {Date|DateString} date2
 *
 * @returns {Boolean}
 */
export function areDatesInSameMonth (date1, date2) {
  date1 = dateStringToDate(date1)
  date2 = dateStringToDate(date2)
  return date1.getMonth() === date2.getMonth()
}
helper.areDatesInSameMonth = areDatesInSameMonth

/**
 * Check if both given dates are on same year
 *
 * @param {Date|DateString} date1
 * @param {Date|DateString} date2
 *
 * @returns {Boolean}
 */
export function areDatesInSameYear (date1, date2) {
  date1 = dateStringToDate(date1)
  date2 = dateStringToDate(date2)
  return date1.getFullYear() === date2.getFullYear()
}
helper.areDatesInSameYear = areDatesInSameYear

/**
 * Check if given edge dates are a whole month
 *
 * @param {Date|DateString} initialDate
 * @param {Date|DateString} finalDate
 *
 * @returns {Boolean}
 */
export function areDatesWholeMonth (initialDate, finalDate) {
  initialDate = dateStringToDate(initialDate)
  finalDate = dateStringToDate(finalDate)
  const wholeMonthDates = getWholeMonthDays(initialDate)
  return equalDates(initialDate, wholeMonthDates[0]) &&
    equalDates(finalDate, wholeMonthDates[wholeMonthDates.length - 1])
}
helper.areDatesWholeMonth = areDatesWholeMonth

/**
 * Returns the date in the middle, computed by days
 *
 * @param {Date|DateString} date1
 * @param {Date|DateString} date2
 *
 * @returns {Date}
 */
export function getCenterDate (date1, date2) {
  date1 = dateStringToDate(date1)
  date2 = dateStringToDate(date2)
  const daysDiff = getDatesDifference(date1, date2)
  return addDays(date1, parseInt(daysDiff / 2))
}
helper.getCenterDate = getCenterDate

/**
 * Returns the date with no timezone (YYY-MM-DDT00:00:00)
 *
 * @param {Date|DateString} date
 *
 * @returns {Date}
 */
export function noTimeZoneDate (datestring) {
  const date = dateStringToDate(datestring)
  return new Date(`${dateToString(date)}T00:00:00.000Z`)
}
helper.noTimeZoneDate = noTimeZoneDate

/**
 * Filters a list of dates in the range specified
 *
 * @param {Date|DateString} date    - The date to check
 * @param {Date|DateString} [minDate] - The minimum date in range
 * @param {Date|DateString} [maxDate] - The maximum date in range
 *
 * @returns {Boolean} True if date is in range
 */
export function isDateInRange (date, minDate, maxDate) {
  if (!minDate && !maxDate) return false
  date = date ? dateStringToDate(date) : undefined
  minDate = minDate ? dateStringToDate(minDate) : undefined
  maxDate = maxDate ? dateStringToDate(maxDate) : undefined
  if (!minDate) return date <= maxDate
  if (!maxDate) return date >= minDate
  return date >= minDate && date <= maxDate
}
helper.isDateInRange = isDateInRange

/**
 * Filters a list of dates in the range specified
 *
 * @param {Date|DateString[]} dates - A list of dates
 * @param {Date|DateString} minDate - The minimum date in range
 * @param {Date|DateString} maxDate - The maximum date in range
 *
 * @returns {DateString[]} Matching dates for that range
 */
export function filterDatesInRange (dates = [], minDate, maxDate) {
  if (!minDate && !maxDate) return dates
  return dates
    .filter(date => isDateInRange(date, minDate, maxDate))
}
helper.filterDatesInRange = filterDatesInRange

/**
 * Returns the age at specific date
 *
 * @param {Date|DateString} birthdate     - The birthdate
 * @param {Date|DateString} referenceDate - The date at which we want know the age. By default is today
 *
 * @returns {number} - The age
 */
export function getAge (birthdate, referenceDate = new Date()) {
  birthdate = dateStringToDate(birthdate)
  referenceDate = dateStringToDate(referenceDate)

  let age = referenceDate.getFullYear() - birthdate.getFullYear()
  const m = referenceDate.getMonth() - birthdate.getMonth()
  if (m < 0 || (m === 0 && referenceDate.getDate() < birthdate.getDate())) {
    age--
  }
  return age
}
helper.getAge = getAge
