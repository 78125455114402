import { encodeAttributes } from '../../../js/helpers/string'
import { getToken, getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-label__tokens.json')['c-label']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/label/data/c-label__tokens.json`)['c-label'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

// Import cross-component shared tokens
const tokensCollections = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/data/ds-tokens-shared.json`)['collections'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokensStates = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/data/ds-tokens-shared.json`)['states'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()

/**
 * The Label Data contains all the data needed to hydrate a label component
 *
 * @typedef {Object}      LabelData

 * @property {String}     text              - The text of the label
 * @property {String}     [collection]      - The collection displayed in the label
 * @property {Object}     [attributes]      - The attributes for the label
 * @property {String}     [extraClasses]    - The extraClasses for the label
 * @property {String}     [variant]         - The variant used for the label
 * @property {String}     [size]            - The size of the font using within the label
 * @property {String}     [state]           - The state used for the label
 * @property {String}     [icon]            - The icon used for the label
 * @property {String}     [iconSize]        - The icon size
 */
export const defaultLabelData = {
  text: '',
  collection: '',
  attributes: {},
  extraClasses: '',
  variant: '',
  size: '',
  state: '',
  icon: '',
  iconSize: ''
}

export const LabelTemplate = (d) => {
  d = { ...defaultLabelData, ...d }

  const currentCollectionToken = d.collection
    ? getToken('collection', d.collection.toLowerCase(), tokensCollections) || undefined
    : undefined
  const currentCollectionColorScheme = currentCollectionToken
    ? currentCollectionToken.colorScheme || ''
    : ''

  const currentStateToken = d.state
    ? getToken('state', d.state, tokensStates) || undefined
    : undefined
  const currentStateModifier = currentStateToken
    ? currentStateToken.modifier || ''
    : ''
  const currentStateColorScheme = currentStateToken
    ? currentStateToken.colorScheme || ''
    : ''
  const currentStateColorSchemeWhite = currentStateToken
    ? currentStateToken.colorSchemeWhite || ''
    : ''

  return `
<span class="c-label ${d.extraClasses || ''}
      ${d.icon ? 'c-label--with-icon' : ''}
      ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''}
      ${d.size ? 'c-label--' + d.size : ''}
      ${d.state
        ? `c-label${currentStateModifier} 
          ${d.variant === 'badge' ? `${currentStateColorScheme}` : `${currentStateColorSchemeWhite}`}`
        : ''}
      ${d.collection
        ? `c-label--collection ${currentCollectionColorScheme}`
        : ''}"
      ${d.attributes ? encodeAttributes(d.attributes) : ''}>
    ${d.icon
      ? `<span class="c-label__icon m-icon m-icon--${d.icon} ${d.iconSize ? `m-icon--size-${d.iconSize}` : ''}"></span>`
      : ''}
    ${d.text}
</span>
 `
}
