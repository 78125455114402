
export const widgetQueries = {
  choiceList: '[data-w-destination-multiple-filter__choice-list]',
  choiceListOptions: '.c-choice-list__options',
  floatingBox: '[data-w-destination-multiple-filter__floating-box]',
  dropdown: '.c-dropdown__element',
  arrow: '.c-dropdown__arrow',
  submit: '[data-w-destination-multiple-filter__floating-box__action="submit"]',
  clear: '[data-w-destination-multiple-filter__floating-box__action="clear"]',
  cancel: '[data-w-destination-multiple-filter__floating-box__action="cancel"]',
  chipList: '.c-dropdown__selected-item-list',
  chipRemove: '[data-c-chip__action="remove"]',
  textBox: '[data-js-component="c-textbox"]',
  placeholderAttr: 'data-w-destination-filter__placeholder'
}

export const defaults = {
  minHeight: '260px',
  maxHeight: '400px'
}
