import { defaultDropdownData, DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import { FloatingBoxTemplate } from '../../components/floating-box/c-floating-box.template'
import { FloatingBoxHeaderTemplate } from '../../components/floating-box/c-floating-box__header.template'
import { FloatingBoxBodyTemplate } from '../../components/floating-box/c-floating-box__body.template'
import { FloatingBoxFooterTemplate } from '../../components/floating-box/c-floating-box__footer.template'
import { defaultTextboxData, TextboxTemplate } from '../../components/textbox/c-textbox.template'
import { defaultButtonData, BtnTemplate } from '../../components/btn/c-btn.template'
import { defaultChoiceListData, ChoiceListTemplate } from '../../components/choice-list/c-choice-list.template'

export const DestinationMultipleFilterTemplate = (d) => {
  return `${DropdownTemplate({
        ...defaultDropdownData,
        icon: 'pin',
        isMultiple: true,
        floatingBoxContent: getFloatingBox(d),
        jsApi: false,
        hiddenLabel: true,
        placeholder: d.placeholder,
        extraClasses: d.extraClasses
    })}`
}

function getFloatingBox (d) {
  return `${FloatingBoxTemplate(
        {
            opened: false,
            gradient: true,
            extraClasses: 'c-dropdown__floating-box',
            attributes: {
                'data-w-destination-multiple-filter__floating-box': ''
            }
        },
        getFloatingBoxContent(d)
    )
        }`
}

function getFloatingBoxContent (d) {
  const header = FloatingBoxHeaderTemplate({}, headerContent(d))
  const body = FloatingBoxBodyTemplate({}, bodyContent(d))
  const footer = FloatingBoxFooterTemplate({}, footerContent(d))
  return `${header + body + footer}`
}

function headerContent (d) {
  return `${TextboxTemplate({
        ...defaultTextboxData,
        placeholder: d.autocompletePlaceholder,
        icon: 'search',
        extraClasses: 'w-destination-multiple-filter__autocomplete'
    })}
${BtnTemplate({
        ...defaultButtonData,
        text: d.clearButtonText,
        variant: 'flat',
        attributes: { 'data-w-destination-multiple-filter__floating-box__action': 'clear' },
        extraClasses: 'w-destination-multiple-filter__clear-btn w-destination-multiple-filter__floating-box__btn'
    })}
${BtnTemplate({
        ...defaultButtonData,
        text: d.okButtonText,
        attributes: { 'data-w-destination-multiple-filter__floating-box__action': 'submit' },
        extraClasses: 'w-destination-multiple-filter__floating-box__btn'
    })}`
}

function bodyContent (d) {
  return `${BtnTemplate({
        ...defaultButtonData,
        text: d.clearButtonText,
        variant: 'flat',
        attributes: { 'data-w-destination-multiple-filter__floating-box__action': 'clear' },
        extraClasses: 'w-destination-multiple-filter__clear-btn w-destination-multiple-filter__floating-box__mbl-btn'
    })}
${ChoiceListTemplate({
        ...defaultChoiceListData,
        id: d.id + 'razor-choice-list',
        method: 'multiple',
        attributes: { 'data-w-destination-multiple-filter__choice-list': '' }
    })}`
}

function footerContent (d) {
  return `${BtnTemplate({
        ...defaultButtonData,
        text: d.cancelButtonText,
        variant: 'flat-neutral',
        attributes: { 'data-w-destination-multiple-filter__floating-box__action': 'cancel' },
        extraClasses: 'w-destination-multiple-filter__floating-box__mbl-btn'
    })}
${BtnTemplate({
        ...defaultButtonData,
        text: d.okButtonText,
        attributes: { 'data-w-destination-multiple-filter__floating-box__action': 'submit' },
        extraClasses: 'w-destination-multiple-filter__floating-box__mbl-btn'
    })}`
}
