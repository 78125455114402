import Autocomplete from '../../components/autocomplete/main'
import { DestinationResultTemplate } from '../../widgets/destination-filter/destination-result.template'
import { DestinationSingleFilterTemplate } from './destination-single-filter.template'
import { elementFromString } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
const globalLocales = register(`window.sundio.i18n.${language}.global`)
const EventEmitter = require('eventemitter3')
const widgetQueries = {
  autocomplete: '[data-js-component="c-autocomplete"]',
  placeholderAttr: 'data-w-destination-filter__placeholder'
}

require('../../components/autocomplete/main')
require('../../components/textbox/main')
export default class DestinationSingleFilter {
  /**
   * Creates a new DestinationFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DestinationsFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel, options = {}) {
    this.element = element
    this.events = options.events || new EventEmitter()
    this.locales = this._getLocales()
    this.track = options.track
    const placeholder = this.element.getAttribute(widgetQueries.placeholderAttr)
    this.drawDestinationSingleFilter(placeholder)
    this.autocompleteElement = this.element.querySelector(widgetQueries.autocomplete)

    this.setFilterModel(filterModel)

    // Init the autocomplete element
    this.autocomplete = new Autocomplete(
      this.autocompleteElement,
      this.getAutocompleteOptionsFromFilterModel())
    // Improve the resultElement
    this.autocomplete.getResultHtml = this.getDestinationResultHTML

    // Bind autocomplete events to filterModel
    this.autocomplete.events.on('submit', (selection) => {
      this.filterModel
        .clearSelection({ silent: true })
        .setSelectedValues([selection.model])
    })
  }

  drawDestinationSingleFilter (placeholder) {
    this.element.appendChild(elementFromString(DestinationSingleFilterTemplate({
      id: this.element.id,
      placeholder,
      jsApi: true,
      value: '',
      locales: this.locales,
      extraClasses: 'w-destination-filter__element',
      track: this.track
    })))
    Component.initDocumentComponentsFromAPI(this.element)
    Component.initComponentActionElements(this.element)
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set props on child views
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel   - The associated FilterModel
   * @param {Object} options
   * @param {Boolean} options.silent    - If true, it will not update the Autocomplete component
   *
   * @returns {DestinationFilter} self instance
   */
  setFilterModel (filterModel, options = {}) {
    this.filterModel = filterModel

    if (!options.silent && this.autocomplete) { this.autocomplete.setOptions(this.getAutocompleteOptionsFromFilterModel()) }

    this.filterModel.events.on('change', () => {
      this.autocomplete.setSelectionFromModel(this.getSelectedFilterValueModel())
    })

    return this
  }

  /**
   * Returns the HTML for a single destination result
   * Note: It should only be invoked on Autocomplete context, so the value of THIS will be an Autocomplete instance
   *
   * @returns {String} The HTML generated string
   *
   */
  getDestinationResultHTML (result) {
    return DestinationResultTemplate(result, { regExp: this.currentSearch.termRegExp })
  }

  /**
   * Returns the selected filter value model, if there's any
   *
   * @returns {(Model|null)}
   *
   */
  getSelectedFilterValueModel () {
    const selectedModels = this.filterModel.getSelectedModels()
    return selectedModels.length
      ? selectedModels[0]
      : null
  }

  /**
   * Returns the AutocompleteOptions based on current filterModel
   *
   * @returns {AutocompleteOptions}
   *
   */
  getAutocompleteOptionsFromFilterModel () {
    const selectedModel = this.getSelectedFilterValueModel()
    return {
      data: this.filterModel.values,
      searchAttributes: ['caption', 'canonicalName'],
      selection: {
        text: selectedModel ? selectedModel.getAttribute('caption') : undefined,
        model: selectedModel
      },
      emptyShownResultsData: this.filterModel.values.getSortedCountriesAndRegions()
    }
  }

  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return { ...globalLocales, ...(customLocaleData || {}) }
  }
}
