import { debounce } from '../../../js/utils'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { ChipTemplate } from '../../components/chip/c-chip.template'
import { elementFromString } from '../../../js/document/html-helper'
import { queueFilterEvent } from '../../../js/helpers/queue-filter-event'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import { DestinationResultTemplate } from '../destination-filter/destination-result.template'
import { DestinationMultipleOptionTemplate } from './destination-multiple-option.template'
import { DestinationMultipleFilterTemplate } from './destination-multiple-filter.template'
import Swipe from '../../objects/swipe/main'
import Textbox from '../../components/textbox/main'
import Component from '../../../js/core/component/component'
import {
  widgetQueries,
  defaults
} from './config'

// Ensure other child component APIs are loaded on time
require('../../components/choice-list/main')
require('../../components/floating-box/main')
require('../../components/textbox/main')
const EventEmitter = require('eventemitter3')
const globalLocales = register(`window.sundio.i18n.${language}.global`)

const widgetApi = 'w-destination-multiple-filter'

const definition = {
  name: widgetApi
}

export default class DestinationMultipleFilter {
  /**
   * Creates a new DestinationMultipleFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DestinationsMultipleFilter
   * * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel, options = {}, isElementRendered = true) {
    this.element = element
    this.events = options.events || new EventEmitter()
    this.locales = this._getLocales()
    this.element.setAttribute('data-js-component', widgetApi)
    const placeholder = this.element.getAttribute(widgetQueries.placeholderAttr)

    this.drawDestinationMultipleFilter(placeholder)

    this.xoiceList = this.element.querySelector(widgetQueries.choiceList)
    this.xoiceListOptions = this.xoiceList.querySelector(widgetQueries.choiceListOptions)

    this.dropdownInput = this.element.querySelector(widgetQueries.dropdown)
    this.arrow = this.element.querySelector(widgetQueries.arrow)
    this.submitElements = this.element.querySelectorAll(widgetQueries.submit)
    this.clearElements = this.element.querySelectorAll(widgetQueries.clear)
    this.cancelElement = this.element.querySelector(widgetQueries.cancel)
    this.chipListElement = this.element.querySelector(widgetQueries.chipList)
    this.chipListElement.innerHTML = ''

    this.textBoxElement = this.element.querySelector(widgetQueries.textBox)
    this.textBoxElement.addEventListener('mousedown', this.onTextBoxFocus, this)
    this.textBoxApi = new Textbox(this.textBoxElement)
    this.textBoxInput = this.textBoxElement.querySelector('input')

    this.textBoxApi.events.on('keyup', this.onKeyUp, this)

    this.delay = 150

    this.debouncedInputChange = debounce(() => this.onInputChange(), this.delay)

    domEventsHelper.attachEvents([
      [this.dropdownInput, { focus: (ev) => this.onFocus(ev) }],
      [this.submitElements[0], { click: (ev) => this.onSubmit(ev) }],
      [this.submitElements[1], { click: (ev) => this.onSubmit(ev) }],
      [this.clearElements[0], { click: (ev) => this.onClear(ev) }],
      [this.clearElements[1], { click: (ev) => this.onClear(ev) }],
      [this.cancelElement, { click: (ev) => this.onCancel(ev) }],
      [this.xoiceList, { change: (ev) => this.onChange(ev) }],
      [this.chipListElement, { mousedown: (ev) => this.onClickChipList(ev) }],
      [this.arrow, { click: (ev) => this.toggle(ev) }]
    ], definition.name)

    // Floating box
    this.floatingBox = this.element.querySelector(widgetQueries.floatingBox)
    this.floatingBoxApi = this.floatingBox['c-floating-box']
    if (isElementRendered) {
      this.floatingBoxApi.setProp('minHeight', defaults.minHeight, { silent: true })
      this.floatingBoxApi.setProp('maxHeight', defaults.maxHeight, { silent: true })
    }

    this._openedEvents = []

    this.setFilterModel(filterModel)

    // Exposed API
    this.element[widgetApi] = {
      getSelectedOptions: () => this.getSelectedOptions(this.options),
      getChipsElements: this.getChipsElements.bind(this),
      updateModelOnChipUpdate: this.updateModelOnChipUpdate.bind(this),
      open: (this.onFocus).bind(this)
    }
  }

  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return { ...globalLocales, ...(customLocaleData || {}) }
  }

  // Set filter model
  setFilterModel (filterModel) {
    this.filterModel = filterModel
    this.options = this.getTree(this.filterModel.values.models)
    this.fallBackModels = this.filterModel.getSelectedModels()
    this.render(this.options)
    this.updateChips()
    this.changeClearButtonVisibility()
  }

  onFocus (ev) {
    this.open()
  }

  onClear () {
    this.clearFilter()
  }

  onCancel () {
    this.clearFilter()
    this.fallBackModels.forEach(option => {
      this.checkOptions(option, true)
    })
    this.changeClearButtonVisibility()
    this.close()
  }

  onSubmit () {
    const currentSelectedValues = this._getValuesFromOptions(this.getSelectedOptionsByLowestType())
    this.close()
    if (JSON.stringify(currentSelectedValues) === JSON.stringify(this._getValuesFromOptions(this.fallBackModels))) return

    this.updateChips()
    this.updateFilterModel()
  }

  onChange (ev) {
    const input = ev.target
    const targetOpt = this._findOptionsByValue(this.options, input.value)
    if (!targetOpt) return
    targetOpt.attributes.isSelected = input.checked
    if (this.hasChildren(targetOpt)) {
      if (!targetOpt.attributes.parentId) this._findElementByOption(targetOpt).parentElement.classList.remove('is-half-checked')
      targetOpt.attributes.isSelected ? this.checkOptions(targetOpt, true) : this.checkOptions(targetOpt, false)
    }
    if (targetOpt.attributes.parentId) this.updateParent(targetOpt)
    this.changeClearButtonVisibility()
  }

  onDocumentClick (ev) {
    const currentSelectedValues = this._getValuesFromOptions(this.getSelectedOptionsByLowestType())
    const shouldEmit = JSON.stringify(currentSelectedValues) !== JSON.stringify(this._getValuesFromOptions(this.fallBackModels))
    queueFilterEvent(ev, this.element, this.onSubmit.bind(this), shouldEmit)
  }

  onClickChipList (ev) {
    if (!ev.target.matches(widgetQueries.chipRemove)) {
      this.onFocus()
      return
    }
    const matchValue = ev.target.parentElement.dataset.value
    this.updateModelOnChipUpdate(matchValue)

    ev.preventDefault()
    ev.stopPropagation()
  }

  updateModelOnChipUpdate (matchValue) {
    const cleanMatchValue = matchValue.includes('||||') ? matchValue.split('||||')[1] : matchValue
    const option = this._findOptionsByValue(this.options, cleanMatchValue)

    if (!option) return
    if (option.attributes.parentId) this.updateParent(option)

    this.checkOptions(option, false)
    this.updateChips()

    this.updateFilterModel()
  }

  onKeyUp (ev) {
    const key = ev.keyCode
    if (!key || (key >= 35 && key <= 40) || key === 13 || key === 27 || key === 9) return
    this.debouncedInputChange()
  }

  onInputChange () {
    const minChars = 1
    const text = this.textBoxInput.value.length >= minChars
      ? this.textBoxInput.value.trim()
      : ''

    if (text === '') {
      this.showingAutocompleteResults = false
      this.render(this.options)
    } else {
      this.showingAutocompleteResults = true
      const valuesToRender = this.filterModel.values.models.filter(model => model.attributes.caption.toLowerCase().includes(text.toLowerCase()))
      this.xoiceListOptions.innerHTML = this.renderList(valuesToRender, text)
      valuesToRender.forEach(option => {
        const element = document.getElementById(option.attributes.value)
        element.addEventListener('click', () => {
          this.onAutocompleteItemClick(option.attributes.value)
        })
      })
    }
    this.changeClearButtonVisibility()
  }

  open () {
    if (this.opened) return this
    this.floatingBoxApi.setProp('opened', true)
    this._openedEvents.push([document, { mousedown: (ev) => this.onDocumentClick(ev) }])
    domEventsHelper.attachEvents(this._openedEvents, definition.name)
    this.opened = true
    this.updateChevron(this.arrow, this.opened)

    return this
  }

  close () {
    if (!this.opened) return this
    this.floatingBoxApi.setProp('opened', false)
    domEventsHelper.detachEvents(this._openedEvents, definition.name)
    this.opened = false
    this.updateChevron(this.arrow, this.opened)
    this.dropdownInput.blur()
    return this
  }

  toggle (ev) {
    if (this.opened) {
      this.close()
    } else {
      this.open()
    }
    ev.preventDefault()
    ev.stopPropagation()
  }

  drawDestinationMultipleFilter (placeholder) {
    const filterTemplate = DestinationMultipleFilterTemplate({
      placeholder,
      autocompletePlaceholder: this.locales.search,
      clearButtonText: this.locales.clearFilter,
      okButtonText: this.locales.ok,
      id: this.element.id,
      cancelButtonText: this.locales.cancel,
      extraClasses: 'w-destination-filter__element'
    })

    this.element.appendChild(elementFromString(filterTemplate))

    Component.initDocumentComponentsFromAPI(this.element)
    Component.initComponentActionElements(this.element)
    Swipe.CreateInstancesOnDocument()
  }

  getTree (items, id = undefined) {
    return items
      .filter(item => item.attributes.parentId === id)
      .map(item => Object.assign(item, { attributes: { ...item.attributes, children: this.getTree(items, item.attributes.value) } }))
  }

  checkOptions (parent, check) {
    if (parent.attributes.children && parent.attributes.children.length > 0) {
      parent.attributes.children.forEach(child => {
        this.setOptionAndElementChecked(child, check)
        if (this.hasChildren(child)) this.checkOptions(child, check)
      })
    } else {
      this.setOptionAndElementChecked(parent, check)
    }
  }

  updateParent (option) {
    const parent = this._findOptionsByValue(this.options, option.attributes.parentId)
    if (!parent) return
    const hasEverySelectChild = (option) => {
      if (!this.hasChildren(option)) return false
      let everySelected = true
      option.attributes.children.forEach(subOption => {
        if (!everySelected) return
        if (!subOption.attributes.isSelected) everySelected = false
      })
      return everySelected
    }

    const hasAnySelectedChild = (option) => {
      if (!this.hasChildren(option)) return false
      let childSelected = false
      option.attributes.children.forEach(subOption => {
        if (childSelected) return
        if (subOption.attributes.isSelected) childSelected = true
      })
      return childSelected
    }

    if (hasEverySelectChild(parent)) {
      this.setOptionAndElementChecked(parent, true)
    } else {
      const hasChildrenHalfCheck = parent.attributes.children.some(o => o.attributes.halfChecked)
      this.setOptionHalfChecked(parent, hasAnySelectedChild(parent) || hasChildrenHalfCheck)
    }

    if (parent.attributes.parentId) this.updateParent(parent)
  }

  updateOptions (options) {
    this.getEverySelectedOption(options)
      .filter(o => o.attributes.type === 'city')
      .forEach(city => {
        this.updateParent(city)
      })
    this.getSelectedOptions(this.options)
      .forEach(o => this.checkOptions(o, true))
  }

  getLowestType () {
    const selectedOptions = this.getSelectedOptions(this.options)
    if (selectedOptions.some(o => o.attributes.type === 'city')) return 'city'
    else if (selectedOptions.some(o => o.attributes.type === 'subregion')) return 'city'
    else if (selectedOptions.some(o => o.attributes.type === 'region')) return 'region'
    else if (selectedOptions.some(o => o.attributes.type === 'country')) return 'country'
  }

  getSelectedOptionsByLowestType () {
    const type = this.getLowestType()
    const allSelectedOptions = this.getEverySelectedOption(this.options)
    return allSelectedOptions.filter(option => option.attributes.type === type)
  }

  setOptionHalfChecked (option, halfChecked = true) {
    this.setOptionAndElementChecked(option, false)
    option.attributes.halfChecked = halfChecked
    this._findElementByOption(option)
      .parentElement.classList[halfChecked ? 'add' : 'remove']('is-half-checked')
  }

  hasChildren (option) {
    return option.attributes.children.length > 0
  }

  setOptionAndElementChecked (option, value) {
    this._findElementByOption(option).parentElement.classList.remove('is-half-checked')
    option.attributes.halfChecked = false
    option.attributes.isSelected = value
    this._findElementByOption(option).checked = value
    if (option.attributes.parentId) this.updateParent(option)
  }

  updateChevron (arrow, opened) {
    if (opened) {
      arrow.classList.remove('m-icon--chevron-down')
      arrow.classList.add('m-icon--chevron-up')
    } else {
      arrow.classList.remove('m-icon--chevron-up')
      arrow.classList.add('m-icon--chevron-down')
    }
  }

  _findOptionsByValue (array, value) {
    const match = array.find(v => v.attributes.value === value)

    if (match) return match
    let result
    for (let i = 0; i < array.length; i++) {
      if (this.hasChildren(array[i])) {
        result = this._findOptionsByValue(array[i].attributes.children, value)
        if (result) return result
      }
    }
    return result
  }

  _findElementByOption (option) {
    return this.element.querySelector(`input[value='${option.attributes.value}']`)
  }

  getEverySelectedOption (options) {
    const selectedOptions = []
    options.forEach(option => {
      if (option.attributes.isSelected) selectedOptions.push(option)
      if (this.hasChildren(option)) {
        const result = this.getEverySelectedOption(option.attributes.children)
        if (result.length > 0) selectedOptions.push(...result)
      }
    })
    return selectedOptions
  }

  getSelectedOptions (options) {
    const selectedValues = []
    options.forEach(option => {
      if (option.attributes.isSelected) selectedValues.push(option)
      else if (this.hasChildren(option)) {
        const result = this.getSelectedOptions(option.attributes.children)
        if (result.length > 0) selectedValues.push(...result)
      }
    })
    return selectedValues
  }

  newChipElement (option) {
    const chipData = {
      value: option.attributes.value,
      text: option.attributes.caption
    }
    return elementFromString(ChipTemplate(chipData))
  }

  updateChips () {
    this._flushChipElements()
    const selectedOptions = this.getSelectedOptions(this.options)
    selectedOptions.forEach(option => {
      this.chipListElement.appendChild(this.newChipElement(option))
    })
  }

  getChipsElements () {
    let chips = ''
    Array.from(this.chipListElement.children).forEach(chip => {
      chip.setAttribute('data-value', 'Destination||||' + chip.getAttribute('data-value'))
      chip.classList.add('c-chip--highlighted')
      chips = chips + chip.outerHTML
    })
    return chips
  }

  _flushChipElements () {
    while (this.chipListElement.firstChild) {
      this.chipListElement.removeChild(this.chipListElement.firstChild)
    }
    return this
  }

  updateFilterModel () {
    const selectedOptions = this.getSelectedOptionsByLowestType()
    this.filterModel.selectedValuesLowestType = selectedOptions
    this.filterModel.clearSelection({ silent: true }).setSelectedValues(selectedOptions)
    this.events.emit('submit', { models: this.getSelectedOptions(this.options) })
  }

  render (destinations) {
    this.optionsListHtml = ''
    destinations.forEach((country, i) => {
      this.optionsListHtml += i === 0 || i === Math.ceil(destinations.length / 2) ? '<div class="c-choice-list__options--column-wrapper">' : ''
      this.renderTree(country)
      this.optionsListHtml += i === Math.ceil(destinations.length / 2) - 1 || i === destinations.length - 1 ? '</div>' : ''
    })
    this.xoiceListOptions.innerHTML = this.optionsListHtml
    this.updateOptions(this.options)
    this.addCollapseEvents()
    domEventsHelper.attachEvents(this._openedEvents, definition.name)
  }

  renderTree (destination) {
    this.renderSingleOption(destination.attributes, this.hasChildren(destination))
    if (this.hasChildren(destination)) {
      this.optionsListHtml += `<div class="c-checkbox__children collapse" data-collapse-child="${destination.attributes.value}">`
      destination.attributes.children.forEach((child) => {
        this.renderTree(child)
      })
      this.optionsListHtml += '</div>'
    }
  }

  addCollapseEvents () {
    const elements = this.element.querySelectorAll('.c-checkbox.has-child.w-destination-multiple-collapse.c-choice-list__option')
    elements.forEach(e => {
      if (e.attributes['data-collapse']) {
        const optionLabel = e.getElementsByClassName('c-checkbox__text-wrapper')[0]
        const inputId = e.attributes['data-collapse'].value

        const event = [optionLabel, {
          click: (ev) => {
            const child = this.element.querySelector(`[data-collapse-child="${inputId}"]`)
            const arrow = this.element.querySelector(`[data-collapse-chevron="${inputId}"]`)

            child.classList.toggle('collapse')
            e.classList.toggle('c-checkbox__bottom-line')
            this.floatingBoxApi.adjustSize()
            this.updateChevron(arrow, !child.classList.contains('collapse'))
            ev.preventDefault()
          }
        }]
        this._openedEvents.push(event)
      }
    })
  }

  renderSingleOption (d, hasChildren) {
    this.optionsListHtml += DestinationMultipleOptionTemplate({ ...d, hasChildren, parentId: this.element.id })
  }

  renderList (options, text) {
    return `<div class="c-autocomplete--destinations"><ul id="autocompleteList" class="c-autocomplete__results">
    ${options.length === 0
        ? `<li class="c-autocomplete__result--no-result" aria-selected="false">
      ${this.locales.sorryNoMatches} <b>${text}</b>
    </li>`
        : options.map(result => {
          return DestinationResultTemplate(result, { hideCounters: true })
        }).join('')}
    </ul></div>`
  }

  selectAutocompleteListOption (id) {
    const targetOpt = this._findOptionsByValue(this.options, id)
    targetOpt.attributes.isSelected = true
  }

  clearAutocomplete () {
    this.showingAutocompleteResults = false
    this.textBoxInput.value = ''
    this.render(this.options)
  }

  clearFilter () {
    if (this.showingAutocompleteResults) {
      this.clearAutocomplete()
    }
    this.clearSelectedOptions()
    this.changeClearButtonVisibility()
  }

  clearSelectedOptions () {
    const selectedOptions = this.getSelectedOptions(this.options)
    if (selectedOptions.length === 0) return
    selectedOptions.forEach(option => {
      this.checkOptions(option, false)
    })
  }

  changeClearButtonVisibility () {
    const selectedOptions = this.getSelectedOptions(this.options)
    if (selectedOptions.length === 0 || this.showingAutocompleteResults) {
      this.clearElements.forEach(el => el.classList.add('hidden'))
    } else {
      this.clearElements.forEach(el => el.classList.remove('hidden'))
    }
  }

  onAutocompleteItemClick (id) {
    this.selectAutocompleteListOption(id)
    this.clearAutocomplete()
    this.changeClearButtonVisibility()
  }

  _getValuesFromOptions (options) {
    return options.map(o => o.attributes.value)
  }

  onTextBoxFocus (ev) {
    ev.stopPropagation()
  }
}
