import DestinationSingleFilter from '../../widgets/destination-single-filter/main'
import DestinationMultipleFilter from '../../widgets/destination-multiple-filter/main'
import registeredEvents from '../../../js/helpers/registered-events'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'

const globalLocales = register(`window.sundio.i18n.${language}.global`)
const EventEmitter = require('eventemitter3')
require('../../widgets/destination-single-filter/main')
require('../../widgets/destination-multiple-filter/main')

const definitionName = 'w-destination-filter'

const attr = {
  track: 'data-track'
}

export default class DestinationFilter {
  /**
   * Creates a new DestinationFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DestinationsFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element
    this.events = new EventEmitter()
    this.locales = this._getLocales()

    this.setFilterModel(filterModel)

    registeredEvents.registerWidgetEvents(definitionName, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set props on child views
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel   - The associated FilterModel
   * @param {Object} options
   * @param {Boolean} options.silent    - If true, it will not update the Autocomplete component
   *
   * @returns {DestinationFilter} self instance
   */
  setFilterModel (filterModel, options = {}) {
    this.filterModel = filterModel
    // Remove base element to be subtituted
    this.element.querySelector('.w-destination-filter__element').remove()

    if (this.filterModel.attributes.isMultiselectable) {
      this.element.classList.add('w-filter--destination-multiple')
      this.autocomplete = new DestinationMultipleFilter(this.element, filterModel, { events: this.events })
    } else {
      this.element.classList.remove('w-filter--destination-multiple')
      this.autocomplete = new DestinationSingleFilter(this.element, filterModel, {
        events: this.events,
        track: this.element.hasAttribute(attr.track) ? this.element.attributes[attr.track].value : null
      })
    }

    return this
  }

  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) { }

    return { ...globalLocales, ...(customLocaleData || {}) }
  }
}
